@import '~antd/dist/antd.css';
.background {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url("../assets/backgournd.png");
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(45%)
}

.dashboard {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}