@import '~antd/dist/antd.css';

$altyorColor: #FF8900;

.ant-btn:hover, .ant-btn:focus {
  border-color: #FF8900;
}

.display-form-nodon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  .container-form-nodon {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    Form {
      display: flex;
      flex-wrap: wrap;
    }
    input {
      border-color: $altyorColor;
    }
    .ant-form-item{
      display: flex;
      flex-direction: column;
      width: 45%;
      margin: 1%;
    }
    .ant-input-group {
      display: flex;
      width: 100%;
    }
    .ant-form-item-label > label {
      color: white;
      text-align: left;
      width: 100%;
    }
    .ant-form-horizontal .ant-form-item-label {
      width: 100%;
    }
  }
  .select-language {
    display: flex;
    width: 45%;
    align-items: center;
    justify-content: space-around;
  }
  .no-crm-nodon {
    display: flex;
    width: 100%;
    .keywords {
      display: flex;
      flex-direction: column;
      width: 47%;
      .ant-radio-wrapper {
        margin: 0;
      }
    }
    .more-input {
      display: flex;
      flex-direction: column;
      width: 50%;
    }
  }
  .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
    width: 40%;
  }

  span.ant-radio + * {
    color: white;
  }

  .ant-switch-checked {
    background-color: $altyorColor;
  }

  .ant-form-item-required {
    width: 100%;
  }

  .ant-form {
    width: 100%;
  }

  .ant-input-group-addon {
    margin: 5px;
    width: 100%;
  }

  .ant-radio-inner {
    border-color: $altyorColor;
  }

  .ant-radio-checked .ant-radio-inner{
    border-color: $altyorColor;
  }
  .ant-radio-inner::after {
    background-color: $altyorColor;
    border-color: $altyorColor;
  }

  .ant-radio-input:hover {
    border-color: $altyorColor;
  }

  .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: $altyorColor;
  }


  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $altyorColor;
  }

}
@media screen and (max-width: 850px)  {
  @import '~antd/dist/antd.css';

  $altyorColor: #FF8900;

  .ant-btn:hover, .ant-btn:focus {
    border-color: #FF8900;
  }

  .display-form-nodon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    .container-form-nodon {
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: center;

      Form {
        display: flex;
        flex-direction: column;
      }
      input {
        border-color: $altyorColor;
      }
      .ant-form-item{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 2%;
      }
      .ant-input-group {
        display: flex;
        width: 100%;
      }
      .ant-form-item-label > label {
        color: white;
        text-align: left;
        width: 100%;
      }
      .ant-form-horizontal .ant-form-item-label {
        width: 100%;
      }
    }

    .select-language {
      display: flex;
      width: 100%;
      align-items: center;

    }

    .no-crm-nodon {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 2%;
      .keywords {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .more-input {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }

    .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
      width: 40%;
    }

    span.ant-radio + * {
      color: white;
    }

    .ant-switch-checked {
      background-color: $altyorColor;
    }

    .ant-form-item-required {
      width: 100%;
    }

    .ant-form {
      width: 100%;
    }

    .ant-input-group-addon {
      margin: 5px;
      width: 100%;
    }

    .ant-radio-inner {
      border-color: $altyorColor;
    }

    .ant-radio-checked .ant-radio-inner{
      border-color: $altyorColor;
    }
    .ant-radio-inner::after {
      background-color: $altyorColor;
      border-color: $altyorColor;
    }

    .ant-radio-input:hover {
      border-color: $altyorColor;
    }

    .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
      border-color: $altyorColor;
    }


    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border-color: $altyorColor;
    }

  }
}