@import '~antd/dist/antd.css';

$altyorColor: #FF8900;

.ant-btn:hover, .ant-btn:focus {
    border-color: $altyorColor;
}

.display-altyor {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    .ant-typography {
        margin: 5px;
    }
    .container-form-altyor {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        Form {
            display: flex;
            flex-wrap: wrap;
            Input {
                border-color: $altyorColor;
            }
            .ant-form-item{
                display: flex;
                width: 45%;
                margin: 1%;
            }
            .ant-input-group {
                 display: flex;
                 width: 100%;
             }
            .ant-form-item-label {
                width: 20%;
                text-align: left;
            }
        }
        .ant-form-item-label {
            width: 20%;
        }
        .ant-form-item{
            display: flex;
            width: 45%;
            margin: 1%;
        }
        .ant-radio-group {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            .ant-radio-wrapper {
                margin: 0;
                width: 40%;
            }
        }
    }
    .form-prospect {
        .input-container-prospect {
            display: flex;
            width: 100%;
        }
        .radio-container-prospect {
            display: flex;
            width: 100%;
        }
    }

    .customer-container {
        display: flex;
        width: 100%;
    }

    .no-crm-altyor {
        display: flex;
        width: 100%;
    }

    .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
        width: 40%;
    }

    span.ant-radio + * {
        color: white;
    }

    label {
        width: 100%;
    }

    .ant-form-item-label > label {
        color: white;
    }

    .ant-switch-checked {
        background-color: $altyorColor;
    }

    .ant-form-item-required {
        width: 100%;
    }

    .ant-form {
        width: 100%;
    }

    .ant-input-group-addon {
        margin: 5px;
        width: 100%;
    }

    .ant-radio-inner {
        border-color: $altyorColor;
    }

    .ant-radio-checked .ant-radio-inner{
        border-color: $altyorColor;
    }
    .ant-radio-inner::after {
        background-color: $altyorColor;
        border-color: $altyorColor;
    }

    .ant-radio-input:hover {
        border-color: $altyorColor;
    }

    .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
        border-color: $altyorColor;
    }


    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border-color: $altyorColor;
    }

}

@media screen and (max-width: 1280px){
    .display-altyor {
        .ant-form-item {
            display: flex;
            flex-direction: column;
        }
        .form-prospect {
            .input-container-prospect {
                display: flex;
                flex-direction: column;
                width: 100%;
            }
            .radio-container-prospect {
                display: flex;
                flex-direction: column;
                width: 100%;
                .criterias-container {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }
            }
        }

        .customer-container {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .no-crm-altyor {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }
}

@media screen and (max-width: 850px){
    .display-altyor {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 70%;
        .ant-typography {
            margin: 5px;
        }
        .container-form-altyor {
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            Form {
                display: flex;
                flex-direction: column;
                Input {
                    border-color: $altyorColor;
                }
                .ant-form-item{
                    display: flex;
                    width: 100%;
                    margin: 1%;
                }
                .ant-input-group {
                    display: flex;
                    width: 100%;
                }
                .ant-form-item-label {
                    width: 100%;
                    text-align: left;
                }
            }
            .ant-form-item-label {
                width: 100%;
            }
            .ant-form-item{
                display: flex;
                width: 100%;
                margin: 1%;
            }
            .ant-radio-group {
                display: flex;
                flex-direction: column;
                width: 100%;
                .ant-radio-wrapper {
                    margin: 0;
                    width: 40%;
                }
            }
        }

        .form-prospect {
            .input-container-prospect {
                display: flex;
                flex-direction: column;
                width: 100%;
            }
            .radio-container-prospect {
                display: flex;
                flex-direction: column;
                width: 100%;
                .criterias-container {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }
            }
        }

        .customer-container {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .no-crm-altyor {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        span.ant-radio + * {
            color: white;
        }

        label {
            width: 100%;
        }

        .ant-form-item-label > label {
            color: white;
        }

        .ant-switch-checked {
            background-color: $altyorColor;
        }

        .ant-form-item-required {
            width: 100%;
        }

        .ant-form {
            width: 100%;
        }

        .ant-input-group-addon {
            margin: 5px;
            width: 100%;
        }

        .ant-radio-inner {
            border-color: $altyorColor;
        }

        .ant-radio-checked .ant-radio-inner{
            border-color: $altyorColor;
        }
        .ant-radio-inner::after {
            background-color: $altyorColor;
            border-color: $altyorColor;
        }

        .ant-radio-input:hover {
            border-color: $altyorColor;
        }

        .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
            border-color: $altyorColor;
        }


        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            border-color: $altyorColor;
        }

    }
}
