
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

.header-home {
  max-height: 15%;
  .header-logo {
    height: 100%;

    .logo-altyor {
      max-height: 100%;
      max-width: 100%;
    }
  }
  .header-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding-right: 1%;
    .title-container {
      max-height: 100%;
      max-width: 100%;
      font-family: 'Open Sans', sans-serif;
      color: white;

      em {
        font-style: normal;
        text-decoration: underline #FF8900;
        text-underline-position: under;
        text-underline-offset: 3px;
      }
    }
    .title-welcome {
      max-height: 100%;
      max-width: 100%;
      font-family: 'Open Sans', sans-serif;
      color: white;
    }
  }
}

@media screen and (max-width: 850px){
  .header-home {
    .header-title {
      .title-welcome {
        font-size: 1.2em;
      }
    }
  }
}

